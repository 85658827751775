const faker = require('faker');

const dob = new Date('2015-03-25');

export const INITIAL_VALUES_DEV = {
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  email: faker.internet.email(),
  phoneNumber: faker.phone.phoneNumber(),
  password: 'testpassword',
  confirmPassword: 'testpassword',
  address: '1211 test St',
  addressLine2: 'Unit 100',
  cityName: 'Spokane',
  zipCode: 99205,
  state: 'Washington',
  role: 'tenant',
  familySize: 4,
  beds: 4,
  monthlyIncome: 1000,
  monthlyRent: 500,
  tenantName: 'tenant',
  tenantEmail: 'tenant@gmail.com',
  tenantNumber: '111-222-3333',
  landlordName: 'landlord',
  landlordAddress: '123 Landlord St',
  landlordAddress2: 'Unit 2',
  landlordCity: 'Landlord City',
  landlordState: 'Washington',
  landlordZip: 99205,
  landlordEmail: 'landlord@gmail.com',
  landlordNumber: '111-222-3333',
  childrenAges: '4, 2',
  owed: 600,
  amountRequested: 450,
  budget: 'Treasury ERA',
  rent: 500.45,
  advocate: false,
  totalChildren: 2,
  unEmp90: true,
  foodWrkr: false,
  minorGuest: true,
  covidFH: true,
  qualifiedForUnemployment: true,
  proofOfRisk: true,
  hispanicHOH: true,
  asianHOH: false,
  blackHOH: false,
  pacificHOH: false,
  whiteHOH: true,
  nativeHOH: false,
  demoNotSayHOH: false,
  gender: 'Male',
  dob: dob,
  hispanic: true,
  asian: false,
  black: false,
  pacific: false,
  white: true,
  native: false,
  demoNotSay: false,
  incomplete: true,
};

export const INITIAL_VALUES_PROD = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  address: '',
  addressLine2: '',
  cityName: '',
  zipCode: '',
  state: '',
  role: 'tenant',
  familySize: '',
  beds: null,
  dob: '',
  monthlyIncome: '',
  tenantName: '',
  tenantEmail: '',
  tenantPhoneNumber: '',
  landlordName: '',
  landlordAddress: '',
  landlordAddress2: '',
  landlordCity: '',
  landlordState: '',
  landlordZip: null,
  landlordEmail: '',
  landlordNumber: '',
  childrenAges: '',
  owed: null,
  amountRequested: null,
  amountApproved: null,
  budget: '',
  rent: null,
  monthlyRent: null,
  advocate: false,
  totalChildren: null,
  unEmp90: false,
  foodWrkr: false,
  minorGuest: false,
  covidFH: false,
  qualifiedForUnemployment: false,
  proofOfRisk: false,
  hispanic: false,
  asian: false,
  black: false,
  pacific: false,
  white: false,
  native: false,
  demoNotSay: false,
  hispanicHOH: false,
  asianHOH: false,
  blackHOH: false,
  pacificHOH: false,
  whiteHOH: false,
  nativeHOH: false,
  demoNotSayHOH: false,
  gender: '',
  incomplete: true,
};

export const INITIAL_VALUES = () => {
  if (process.env.NODE_ENV === 'production') return INITIAL_VALUES_PROD;

  return INITIAL_VALUES_DEV;
};
